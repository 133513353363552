import {
  faChevronDown,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faTimes,
  faTriangleExclamation,
  faCheck,
  faLightbulb,
  faPuzzlePieceSimple,
  faExclamationTriangle,
  faExclamationCircle,
  faCommentSmile,
  faXmark,
  faAngleRight,
  faCoins,
} from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { faGlobe } from "@fortawesome/pro-regular-svg-icons";

library.add(
  faChevronDown,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faTimes,
  faTriangleExclamation,
  faCheck,
  faLightbulb,
  faPuzzlePieceSimple,
  faExclamationTriangle,
  faExclamationCircle,
  faCommentSmile,
  // @ts-ignore
  faCircle,
  faXmark,
  faAngleRight,
  faCoins,
  faGlobe
);
